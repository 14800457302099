<template>
  <div v-if="isNotWebView">
    <browser-not-supported v-if="isNoSupportedBrowser" />
    <div v-else-if="appInit">
      <hedios-header id="mast" />
      <main>
        <div
          class="blk-main-ec"
          :class="{ isSafari: isSafari }"
        >
          <div class="container-fluid">
            <div class="row">
              <div
                class="col-sm-2"
                :class="{ 'scrolled-past': hasScrolledPast }"
              >
                <menu-rapide
                  v-if="!isTabOrMob"
                  :items="fastMenuItems"
                />
              </div>
              <div
                class="home col-sm-10"
                :class="[bodyClass]"
              >
                <div
                  v-if="isMobile && isBusinessAccount"
                  class="blk-name _mob"
                >
                  {{ $t('salutation') }},<br>{{ getUsername }}
                </div>
                <div
                  v-else-if="isMobile"
                  class="blk-name _mob"
                >
                  {{ $t("salutation") }},<br>{{ sfUserName }}
                </div>
                <error-alert
                  v-if="mergedErrors.length"
                  :errors="mergedErrors"
                />
                <router-view />
              </div>
            </div>
          </div>
        </div>
        <div
          ref="targetElement"
          class="blk-area-foot"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <blk-brochure />
                <blk-contact />
              </div>
            </div>
          </div>
        </div>
      </main>
      <div
        v-if="isMobile"
        class="bloc_mob"
      >
        <div class="bloc_btn">
          <a href="tel:+330153459800">
            <i class="icon-telephone" />
            <span>Appeler</span>
          </a>
        </div>
      </div>
      <hedios-footer />
    </div>
    <hedios-spinner
      v-else
      message="Chargement..."
      size="big"
    />
  </div>
  <div v-else>
    <browser-not-supported v-if="isNoSupportedBrowser" />
    <div v-else-if="appInit">
      <div>
        <main>
          <div
            class="home col-sm-12"
            :class="[bodyClass]"
          >
            <router-view />
          </div>
        </main>
      </div>
    </div>
    <hedios-spinner
      v-else
      message="Chargement..."
      size="big"
    />
  </div>
</template>

<script>
// @ is an alias to /src .
import { mapGetters } from 'vuex';
import { isMobile, isTablet, isBrowser, isSafari } from 'mobile-device-detect';
import lodash from 'lodash';
import HediosHeader from '@/components/header/Header.vue';
import HediosFooter from '@/components/footer/Footer.vue';
import FastMenu from '@/components/utils/FastMenu.vue';
import BlkBrochure from '@/components/footer/BlkBrochure.vue';
import BlkContact from '@/components/footer/BlkContact.vue';
import ErrorAlert from '@/components/utils/ErrorAlert.vue';
import HediosSpinner from '@/components/utils/HediosSpinner.vue';
import BrowserNotSupported from '@/components/utils/BrowserNotSupportedMsg.vue';
import { dateFormatLong } from '@/utils/date-utils';
import { supportedBrowsers } from '@/utils/index';
import fastMenuItems from '@/data/fast-menu';

export default {
  name: 'Hedios',
  components: {
    'hedios-header': HediosHeader,
    'hedios-footer': HediosFooter,
    'menu-rapide': FastMenu,
    'blk-brochure': BlkBrochure,
    'blk-contact': BlkContact,
    'error-alert': ErrorAlert,
    'hedios-spinner': HediosSpinner,
    BrowserNotSupported,
  },
  data() {
    return {
      fastMenuItems,
      isMobile,
      isBrowser,
      isTablet,
      isSafari,
      dateFormatLong,
      browserMsg: '',
      supportedBrowsers,
      hasScrolledPast: false
    };
  },
  computed: {
    ...mapGetters([
      'isDrupalAuth',
      'sfUser',
      'sfUserName',
      'tunnels',
      'sfErrors',
      'tunnelsErrors',
      'ghCommerceErrror',
      'operationsErrors',
    ]),
    mergedErrors() {
      const globalError = this.sfErrors;

      if (Array.isArray(this.ghCommerceErrror)) {
        lodash.concat(globalError, this.ghCommerceErrror);
      }
      if (Array.isArray(this.tunnelsErrors)) {
        lodash.concat(globalError, this.tunnelsErrors);
      }
      if (Array.isArray(this.operationsErrors)) {
        lodash.concat(globalError, this.operationsErrors);
      }
      return globalError;
    },
    isNotWebView() {
      return (this.$route.meta.type !== 'webview');
    },
    isNoSupportedBrowser() {
      return !this.supportedBrowsers.test(navigator.userAgent);
    },
    appInit() {
      return ((this.isDrupalAuth && (![undefined, null, false, ''].includes(this.sfUser))) || (this.isNoSupportedBrowser) || (this.mergedErrors.length > 0));
    },
    bodyClass() {
      if ([undefined, null, false].includes(this.$route.meta.containerClass)) {
        return '';
      }

      return this.$route.meta.containerClass;
    },
    isTabOrMob() {
      return this.isTablet || this.isMobile;
    },
    isBusinessAccount() {
      return this.sfUser.isBusinessAccount;
    },
    getUsername() {
      return this.sfUser.name;
    }
  },
  mounted() {
    // Adds an event listener to the window object that listens the "scroll" event
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    // Used to determine whether the user has scrolled past a certain element on the page.
    handleScroll() {
      // Get reference to the target element
      const targetElement = this.$refs.targetElement
      // Check if target element exists
      if (!targetElement) return

      const targetViewportTop = targetElement.getBoundingClientRect().top + window.scrollY
      if (targetViewportTop < window.scrollY) {
        // Target element is below the current scroll position
        this.hasScrolledPast = true
      } else {
        // Target element is above the current scroll position
        this.hasScrolledPast = false
      }
    }
  }
};
</script>

<style lang="scss">
.blk {
  &-main-ec {
    background-color: $accent-23;
    padding: 60px 0 0 0;
    display: inline-block;
    width: 100%;

    @media screen and (max-width: $breakpoint-xs) {
      padding: 35px 0 0;
    }

    >.container-fluid {
      >.row {
        display: flex;
      }
    }

    .col-sm-2 {
      padding-left: 0;
      padding-right: 0;
      background-color: $white;

      @media screen and (max-width: $breakpoint-m) {
        display: none;
      }
    }

    .col-sm-10 {
      padding-top: 150px;
      padding-bottom: 75px;
      padding-right: 4.5%;
      padding-left: 4.5%;

      @media screen and (max-width: $breakpoint-m) {
        width: 100%;
        padding: 10% 15px 5px 15px;
      }

      @media screen and (max-width: $breakpoint-s) {
        padding: 5% 15px 5px 15px;
      }
    }
  }

  &-access {
    background: $accent-4;

    h2 {
      padding: 12px 0;
      background: $accent-2;
      text-align: center;
      color: $white;
      font-size: 1.25em;
      font-family: $font-light;
    }

    ul {
      display: inline-block;
      width: 100%;
    }

    li {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid $accent-21;
      position: relative;
    }

    a {
      text-transform: uppercase;
      font-family: $font-reg;
      display: inline-block;
      width: 100%;
      padding: 12px 40px 12px 15px;
      color: $black;
      font-size: 0.9em;
      text-decoration: none;
    }

    .btn-sub-level {
      position: absolute;
      right: 15px;
      top: 12px;
      background: none;
      color: $accent-2;
      border: none;
      cursor: pointer;
      @include transition(0.25s);
      width: 100%;

      i {
        float: right;
      }

      &.active {
        i {
          @include rotate(270deg);
        }
      }
    }

    .sub-level {
      background-color: $white;
      padding: 15px;
      width: 100%;

      li {
        border: none;
      }

      a {
        color: $black;
        text-transform: none;
        padding: 5px 5px 5px 15px;
        position: relative;

        &:before {
          content: '>';
          position: absolute;
          left: 0;
        }
      }
    }

    .inner-wrapper-sticky {
      background: $white;
    }

    &.is-affixed {
      background: none;

      >div {
        margin-top: 64px;
      }
    }

    @media screen and (max-width: $breakpoint-s) {
      &.isBrowser {
        display: none;
      }

      position: fixed;
      right: 0;
      top: 128px;
      z-index: 5;
      background-color: $accent-2;
      width: 100%;

      h2 {
        display: none;
      }

      li {
        background-color: $accent-2;
        padding: 0 20px;
      }

      a {
        color: $white;
      }

      .btn-sub-level {
        color: $white;
      }

      .sub-level {
        padding: 5px;
        background-color: $accent-2;
        margin-bottom: 15px;

        li {
          background: none;
        }

        a {
          color: $white;
        }
      }
    }
  }

  .clearfix:after {
    display: block;
    content: "";
    clear: both;
  }

  &-area-foot {
    background-color: $white;
    padding: 100px 0;

    .col-md-10 {
      float: right;
      padding: 0 4.5%;
    }

    .col-md-6 {
      padding-left: 35px;

      &:first-child {
        padding-left: 15px;
        padding-right: 35px;
      }

      >div {
        padding: 30px 25px;
        min-height: 310px;
        text-align: center;
      }
    }

    h2 {
      font-family: $montserratlight;
      text-transform: uppercase;
      font-size: 1.75em;
      color: $white;
      display: inline-block;
      width: 100%;
      padding-bottom: 15px;
    }

    .blk {
      &-brochure {
        background-image: linear-gradient($accent-4, #5786b7);

        a {
          margin: 15px auto 0 auto;
          min-width: 215px;
        }
      }

      &-contact {
        background-image: linear-gradient($accent-2, #efbc67);
        color: $white;

        strong {
          font-family: $montserratsemibold;
          font-size: 2em;
          padding-bottom: 20px;
          margin-bottom: 20px;
          display: inline-block;
          width: 100%;
          position: relative;

          &:after {
            width: 45px;
            content: '';
            height: 4px;
            background-color: $white;
            position: absolute;
            bottom: 0;
            left: calc(50% - 22px);
          }
        }

        p {
          font-family: $font-semi;
        }
      }
    }

    @media screen and (max-width: $breakpoint-m) {
      padding: 50px;

      .col-md-10 {
        padding: 0;
        width: 100%;
      }

      .col-md-6 {
        padding: 15px;

        &:first-child {
          padding: 15px;
        }
      }
    }

    @media screen and (max-width: $breakpoint-xs) {
      padding: 0;

      .container-fluid,
      .col-md-6,
      .col-md-6:first-child {
        padding: 0;
      }

      .col-md-6>div {
        min-height: 1px;
      }
    }
  }
}

html {
  scroll-behavior: smooth;
}</style>
