import {
  sfCustomWSHTTP,
} from '@/axios';
import { opportunityInitialState } from '@/store/modules/functions-utils';

const opportunities = {
  state: () => (opportunityInitialState()),
  mutations: {
    INIT_OPPORTUNITY_ERROR(state) {
      state.opportunityErrors = [];
    },
    INIT_OPPORTUNITY(state) {
      state.opportunities = {
        collecte: [],
        general: [],
        walletShare: [],
        reSouscription: [],
        retirementPlanning: [],
        souscription: [],
        transfertPER: [],
        propositionArbitrage: [],
        propositionArbitrageGHGH: [],
        allOppTransPer:[]
      };
    },
    INIT_OPPORTUNITY_TRANS_PER_GAGNEE(state) {
      state.opportunities.allOppTransPer = [];
    },
    ADD_OPPORTUNITY_ERROR(state, error) {
      state.opportunityErrors.push(error);
    },
    ADD_OPPORTUNITY_COLLECTE(state, opp) {
      state.opportunities.collecte.push(opp);
    },
    ADD_OPPORTUNITY_GENERAL(state, opp) {
      state.opportunities.general.push(opp);
    },
    ADD_OPPORTUNITY_WALLET_SHARE(state, opp) {
      state.opportunities.walletShare.push(opp);
    },
    ADD_OPPORTUNITY_RE_SOUSCRIPTION(state, opp) {
      state.opportunities.reSouscription.push(opp);
    },
    ADD_OPPORTUNITY_RETIREMENT_PLANNING(state, opp) {
      state.opportunities.retirementPlanning.push(opp);
    },
    ADD_OPPORTUNITY_SOUSCRIPTION(state, opp) {
      state.opportunities.souscription.push(opp);
    },
    ADD_OPPORTUNITY_TRANSFERT_PER(state, opp) {
      state.opportunities.transfertPER.push(opp);
    },
    ADD_ALL_OPPORTUNITY_TRANS_PER_GAGNEE(state, response) {
      state.opportunities.allOppTransPer = response.data;
    },
    ADD_OPPORTUNITY_PROPOSITION_ARBITRAGE(state, opp) {
      state.opportunities.propositionArbitrage.push(opp);
    },
    ADD_OPPORTUNITY_PROPOSITION_ARBITRAGE_GH_GH(state, opp) {
      state.opportunities.propositionArbitrageGHGH.push(opp);
    },
    ADD_OPPORTUNITY_OTHERS(state, opp) {
      state.opportunities.autresOPP.push(opp);
    },
    DELETE_OPPORTUNITY(state, infos) {
      state.opportunities[infos.categ].splice(infos.index, 1);
    },
  },
  actions: {
    load_client_opportunities(context, args) {
      context.commit('INIT_OPPORTUNITY_ERROR');
      context.commit('INIT_OPPORTUNITY');

      sfCustomWSHTTP.get(`${args.idFicheContact}/opportunity/`)
        .then((response) => {
          if (response.status === 200) {
            response.data.forEach((opp) => {
              switch (opp.recordTypeDeveloperName) {
                case 'Collecte':
                  if((opp.type === 'Proposition arbitrage') && (opp.affichageNitification)) {
                    context.commit('ADD_OPPORTUNITY_PROPOSITION_ARBITRAGE_GH_GH', opp);
                  }
                  else {
                    context.commit('ADD_OPPORTUNITY_COLLECTE', opp);
                  }
                  break;
                case 'General':
                  context.commit('ADD_OPPORTUNITY_GENERAL', opp);
                  break;
                case 'WalletShareOpportunity':
                  context.commit('ADD_OPPORTUNITY_WALLET_SHARE', opp);
                  break;
                case 'Re_souscription':
                  context.commit('ADD_OPPORTUNITY_RE_SOUSCRIPTION', opp);
                  break;
                case 'RetirementPlanning':
                  context.commit('ADD_OPPORTUNITY_RETIREMENT_PLANNING', opp);
                  break;
                case 'Souscription':
                  context.commit('ADD_OPPORTUNITY_SOUSCRIPTION', opp);
                  break;
                case 'Transfert_PER':
                  context.commit('ADD_OPPORTUNITY_TRANSFERT_PER', opp);
                  break;
                case 'Proposition_Arbitrage':
                  context.commit('ADD_OPPORTUNITY_PROPOSITION_ARBITRAGE', opp);
                  break;

                default:
                  context.commit('ADD_OPPORTUNITY_OTHERS', opp);
              }
            });
          }
          else {
            context.commit('INIT_OPPORTUNITY_ERROR', response);
          }
        })
        .catch((error) => {
          context.commit('INIT_OPPORTUNITY_ERROR', error);
        });
    },
    load_client_all_opp_transfert_per(context, args) {
      context.commit('INIT_OPPORTUNITY_ERROR');
      context.commit('INIT_OPPORTUNITY_TRANS_PER_GAGNEE');

      const params = new URLSearchParams();
      params.append('stage', 'all');
      params.append('recordtype', 'Transfert_PER');

      sfCustomWSHTTP.get(`${args.idFicheContact}/opportunity/`, { params })
        .then((response) => {
          if (response.status === 200) {
            context.commit('ADD_ALL_OPPORTUNITY_TRANS_PER_GAGNEE', response);
          }
          else {
            context.commit('INIT_OPPORTUNITY_ERROR', response);
          }
        })
        .catch((error) => {
          context.commit('INIT_OPPORTUNITY_ERROR', error);
        });
    },
    delete_client_opportunity(context, infos) {
      context.commit('DELETE_OPPORTUNITY', infos);
    },
  },
  getters: {
    opportunityErrors: (state) => state.opportunityErrors,
    opportunities: (state) => state.opportunities,
    oppPropositionArbitrage: (state) => state.opportunities.propositionArbitrage,
    oppPropositionArbitrageGHGH: (state) => state.opportunities.propositionArbitrageGHGH,
    oppTransfertPER: (state) => state.opportunities.transfertPER,
    oppSouscription: (state) => state.opportunities.souscription,
    oppRetirementPlanning: (state) => state.opportunities.retirementPlanning,
    oppReSouscription: (state) => state.opportunities.reSouscription,
    oppWalletShare: (state) => state.opportunities.walletShare,
    oppWeneral: (state) => state.opportunities.general,
    oppCollecte: (state) => state.opportunities.collecte,
    allOppTransPer: (state) => state.opportunities.allOppTransPer,
  },
};

export default opportunities;
