<template>
  <li v-if="displayMenuItem(item.context)">
    <a
      v-if="item.subs"
      :href="getLink(item.to)"
      @click="setShowSub"
      v-html="item.slot"
    />
    <a
      v-else
      :href="getLink(item.to)"
      :target="getLinkTarget(item.to)"
      v-html="item.slot"
    />
    <template v-if="item.subs">
      <button
        class="btn-sub-level"
        :class="{ active: showSub }"
        @click="setShowSub"
      >
        <i class="icon-slider-droite" />
      </button>
      <div
        v-show="showSub"
        class="sub-level"
      >
        <ul>
          <li
            v-for="(subItem, subIndex) in item.subs"
            :key="subIndex"
          >
            <a
              :href="getLink(subItem.to)"
              :target="getLinkTarget(subItem.to)"
              v-html="subItem.slot"
            />
          </li>
        </ul>
      </div>
    </template>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobile, isBrowser } from 'mobile-device-detect';
import lodash from 'lodash';

export default {
  name: 'FastMenuElement',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isMobile,
      isBrowser,
      showSub: false,
    };
  },
  computed: {
    ...mapGetters(['sfUser', 'contrats', 'groupedGH']),
    isPersonAccount() {
      return this.sfUser.isPersonAccount;
    },
    isClientMGH() {
      return ((this.sfUser.segment !== undefined) && (this.sfUser.segment === 'Client MGH'));
    },
    haveContratVie() {
      return ((this.contrats !== undefined) && (this.contrats.length > 0));
    },
    haveGammesH() {
      return ((this.groupedGH !== undefined) && (this.groupedGH.length > 0));
    },
    realiserUnOperation() {
      return (!this.haveContratVie) ? `${process.env.VUE_APP_DRUPAL_BASE_URL}/souscrire/gammes-h` : '/mon-cadre-d-investissement';
    },
  },
  methods: {
    setShowSub() {
      this.showSub = !this.showSub;
    },
    displayMenuItem(context) {
      let show = this.sfUser.isPersonAccount;
      let hasContractPER = (lodash.filter(this.sfUser.contratsVie, (contrat) => contrat.sousCategorie  === 'RETRAITE').length > 0);

      switch (context) {
        case 'mandat':
          show = ((![undefined, null, false, ''].includes(this.sfUser)) && (this.sfUser.segment === 'Client MGH') && (this.sfUser.isPersonAccount));
          break;

        case 'gammesh':
          show = ((![undefined, null, false, ''].includes(this.groupedGH)) && (this.groupedGH.length > 0) && (this.sfUser.isPersonAccount));
          break;

        case 'manyContracts':
          show = ((![undefined, null, false, ''].includes(this.contrats)) && (this.contrats.length > 1) && (this.sfUser.isPersonAccount)) || (hasContractPER);
          break;

        case 'oneContract':
          show = ((![undefined, null, false, ''].includes(this.contrats)) && (this.contrats.length === 1) && (this.sfUser.segment !== 'Client MGH') && (this.sfUser.isPersonAccount) && !hasContractPER);
          break;

        case 'oneContractMandat':
          show = ((![undefined, null, false, ''].includes(this.contrats)) && (this.contrats.length === 1) && (this.sfUser.segment === 'Client MGH') && (this.sfUser.isPersonAccount));
          break;

        case 'prospect':
          show = ((![undefined, null, false, ''].includes(this.contrats)) && (this.contrats.length === 0) && (this.sfUser.isPersonAccount) || (this.sfUser.isLead));
          break;
        
        case 'all':
          show = true;
          break;

        default:
          break;
      }

      return show;
    },
    getLink(to) {
      let toLink = to;

      switch (to) {
        case 'newContrat':
          toLink = `${process.env.VUE_APP_DRUPAL_BASE_URL}/souscrire/gammes-h`;
          break;

        case 'realiserUnOpe':
          toLink = this.realiserUnOperation;
          break;

        case 'versement':
          toLink = (this.contrats.length === 1) ? `${process.env.VUE_APP_DRUPAL_BASE_URL}/souscrire/versement/${this.contrats[0].refCtt}` : '';
          break;

        case 'arbitrage':
          toLink = (this.contrats.length === 1) ? `${process.env.VUE_APP_DRUPAL_BASE_URL}/souscrire/arbitrage/${this.contrats[0].refCtt}` : '';
          break;

        case 'mandat':
          toLink = (this.contrats.length === 1) ? `${process.env.VUE_APP_DRUPAL_BASE_URL}/souscrire/le-mandat-gammes-h/${this.contrats[0].refCtt}` : '';
          break;

        case 'calendly':
          toLink = (this.sfUser.calendlyUrl) ? this.sfUser.calendlyUrl + '&utm_medium=espace_client' : '';
          break;

        case 'contact':
          toLink = `${process.env.VUE_APP_DRUPAL_BASE_URL}/contact`;
          break;

        case 'produit-affiche':
          toLink = '/#produit-a-laffiche';
          break;

        default:
      }

      return toLink;
    },
    getLinkTarget(to) {
      let target = '';
      const toUrl = this.getLink(to);
      const domain = toUrl.replace('http://', '').replace('https://', '').split('/')[0];

      if ((domain !== '') && (domain !== window.location.host)) {
        target = '_blank';
      }

      return target;
    },
  },
};
</script>
